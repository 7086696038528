<template>
  <div class="relative h-full">
    <div class="flex h-full flex-col items-center justify-center">
      <div class="w-full max-w-4xl px-4">
        <card class="mx-auto py-12">
          <div class="mx-auto flex h-full max-w-2xl flex-col justify-center">
            <div class="mb-4 flex flex-wrap justify-start px-8">
              <div class="w-full pb-12"></div>
              <!--TODO: vue3 replace require with static import-->
              <img
                alt="FirstVet logo"
                :src="require('@/assets/svg/firstvet_logo.svg')"
                class="mx-auto h-12 w-auto"
              />
            </div>
            <div
              v-if="
                currentUiState === UI_STATES.idle ||
                currentUiState === UI_STATES.error
              "
            >
              <div
                v-if="currentUiState === UI_STATES.error"
                class="text-red-600"
              >
                {{ $i18n.tc('call_triage.auth.could_not_validate_email') }}
              </div>
              <div class="flex flex-row">
                <base-input
                  v-model="otpInput"
                  type="text"
                  placeholder="Enter OTP"
                  class="w-full"
                />
                <base-button
                  class="ml-2"
                  color="primary"
                  @click="runVerification"
                  >{{ $i18n.tc('call_triage.auth.confirm') }}</base-button
                >
              </div>
            </div>
            <div v-else-if="currentUiState === UI_STATES.loading">
              <div class="flex justify-center">
                <base-spinner size="xxl" :loading="true" />
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { AuthApi } from '@/api';
import { mapActions } from 'vuex';

const UI_STATES = {
  idle: 'idle',
  loading: 'loading',
  error: 'error',
};
export default {
  data() {
    return {
      otpInput: '',
      UI_STATES,
      currentUiState: UI_STATES.loading,
    };
  },
  mounted() {
    if (this.shouldRunVerification()) {
      this.runVerification();
    }
    this.currentUiState = UI_STATES.idle;
  },
  methods: {
    ...mapActions('auth', ['setAuthData']),
    shouldRunVerification() {
      const { verificationRef, otp: otpParam } = this.$route.query;
      return verificationRef && otpParam;
    },
    async runVerification() {
      this.currentUiState = UI_STATES.loading;
      const { verificationRef, otp: otpParam } = this.$route.query;
      const otpToSend = this.otpInput ? this.otpInput : otpParam;
      try {
        const token = localStorage.getItem('tempToken');
        // TODO: vue3 add axios import
        axios.defaults.headers.common.Authorization = token
          ? `Bearer ${token}`
          : '';

        const { data } = await AuthApi.confirmEmailVerification({
          verificationRef,
          otp: otpToSend,
        });
        if (data?.isVerified) {
          localStorage.removeItem('tempToken');
          await this.setAuthData(token);
          this.$router.push({
            name: 'call-triage-dashboard',
          });
        }
      } catch (error) {
        console.error('error', error);
        this.currentUiState = UI_STATES.error;
      }
    },
  },
};
</script>
