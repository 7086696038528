<template>
  <div class="relative h-full">
    <div class="flex h-full flex-col items-center justify-center">
      <div class="w-full max-w-4xl px-4">
        <card class="mx-auto py-12">
          <div class="mx-auto flex h-full max-w-2xl flex-col justify-center">
            <div class="mb-4 flex flex-wrap justify-start px-8">
              <div class="w-full pb-12"></div>
              <!--TODO: vue3 replace require with static import-->
              <img
                alt="FirstVet logo"
                :src="require('@/assets/svg/firstvet_logo.svg')"
                class="mx-auto h-12 w-auto"
              />
            </div>
            <p class="text-center text-red-600">
              {{ $i18n.tc('call_triage.auth.only_for_sos_users') }}
            </p>
            <base-button
              color="primary"
              class="my-4 w-full"
              :to="firstvetAuthUrl"
              :href="firstvetAuthUrl"
              data-testid="login-button"
            >
              {{ $i18n.tc('call_triage.auth.login_signup') }}
            </base-button>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { FIRSTVET_AUTH_URL } from '@/constants';
import { mapGetters } from 'vuex';

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      platform: 'getPlatform',
    }),
    firstvetAuthUrl() {
      const route = this.$router.resolve({
        name: 'sos-passback',
        params: {
          platform: 'sv',
        },
      });
      const absoluteURL = new URL(route.href, window.location.origin).href;
      return `${FIRSTVET_AUTH_URL}/se/?platform=vetpanel&source=web.vetpanel&redirect_url=${absoluteURL}`;
    },
  },
  beforeMount() {
    this.currentPlatform = 'sv';
  },
};
</script>
