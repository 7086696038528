var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative h-full"},[_c('div',{staticClass:"flex h-full flex-col items-center justify-center"},[_c('div',{staticClass:"w-full max-w-4xl px-4"},[_c('card',{staticClass:"mx-auto py-12"},[_c('div',{staticClass:"mx-auto flex h-full max-w-2xl flex-col justify-center"},[_c('div',{staticClass:"mb-4 flex flex-wrap justify-start px-8"},[_c('div',{staticClass:"w-full pb-12"},[_c('div',{staticClass:"flex justify-between -space-x-1"},[_c('div',{staticClass:"flex items-center"},_vm._l((_vm.imageNames),function(imageName){return _c('img',{key:imageName,staticClass:"h-6 w-auto",attrs:{"alt":"firstvet logo","src":require(("@/assets/svg/flags/" + imageName + ".svg"))}})}),0),_c('div',{staticClass:"justify-self-end"},[_c('v-select',{staticClass:"w-full min-w-40",attrs:{"placeholder":"Select region","options":_vm.regions,"label":"name","value-attr":"id","reduce":function (at) { return at.id; },"clearable":false},on:{"input":_vm.goToRegionLogin},model:{value:(_vm.currentPlatform),callback:function ($$v) {_vm.currentPlatform=$$v},expression:"currentPlatform"}})],1)])]),_c('img',{staticClass:"mx-auto h-12 w-auto",attrs:{"alt":"FirstVet logo","src":require('@/assets/svg/firstvet_logo.svg')}})]),_c(_vm.loginComponent,{tag:"component",attrs:{"legacy-login":_vm.legacyLogin,"clinic-slug":_vm.clinicSlug,"error-msg":_vm.errorMsg}})],1),_c('div',{staticClass:"flex w-full flex-col items-center justify-center"},[_c('button',{staticClass:"flex items-center text-sm text-gray-700 hover:text-gray-900",attrs:{"data-testid":"change-login"},on:{"click":function($event){_vm.legacyLogin = !_vm.legacyLogin}}},[_vm._v(" "+_vm._s(_vm.legacyLogin ? 'Use new login' : 'Use old login')+" "),_c('fv-icon',{attrs:{"icon":"chevron-right","size":"xs"}})],1),_c('button',{staticClass:"flex items-center text-sm text-gray-700 hover:text-gray-900",attrs:{"data-testid":"sos-login"},on:{"click":function () {
                _vm.$router.push({
                  name: 'sos',
                  params: {
                    platform: 'sv',
                  },
                });
              }}},[_vm._v(" sos.eu login "),_c('fv-icon',{attrs:{"icon":"chevron-right","size":"xs"}})],1)])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }