<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <form
      class="min-h-full bg-white px-6 pt-6"
      @submit.prevent="sendForgotPassword"
    >
      <ValidationProvider
        v-slot="{ errors }"
        rules="required|email"
        name="email"
      >
        <base-label for="email"> Email address </base-label>
        <base-input
          v-model="forgotPasswordEmail"
          focus
          class="mb-2"
          placeholder="Enter your email address"
          name="email"
          type="email"
          :disabled="!editable"
        />
        <p v-if="errors.length" class="my-2 text-sm text-red-800">
          {{ errors[0] }}
        </p>
      </ValidationProvider>
      <div class="mt-4 mb-2 flex w-full justify-end">
        <base-button
          color="cancel"
          type="button"
          class="mr-2"
          @click.prevent.native="$emit('cancel')"
        >
          Cancel
        </base-button>
        <base-button
          :loading="loading"
          color="primary"
          :disabled="!forgotPasswordEmail || invalid"
        >
          Send reset email
        </base-button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import AuthApi from '@/api/modules/auth';

export default {
  props: {
    email: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      forgotPasswordEmail: '',
      loading: false,
    };
  },
  mounted() {
    this.forgotPasswordEmail = this.email;
  },
  methods: {
    sendForgotPassword() {
      this.loading = true;
      return AuthApi.requestNewPassword({ email: this.forgotPasswordEmail })
        .then(res => {
          this.loading = false;
          this.$emit('cancel');
          this.$notify({
            group: 'primary',
            title: 'Password reset',
            text: res.data.message,
            duration: 6000,
          });
          this.forgotPasswordEmail = '';
        })
        .catch(e => {
          this.$notify({
            group: 'error',
            title: 'Something went wrong',
            text: e.message,
          });
          this.loading = false;
        });
    },
  },
};
</script>
