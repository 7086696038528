import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';
import { FolksamTriageJourneyBody, NextTriageBody, StartTriageBody } from '@/types';

export default {
  startTriage(startTriageBody: StartTriageBody): AxiosPromise {
    return axios.post('/api/v2/triage/folksam/start', startTriageBody);
  },

  nextTriage(nextTriageBody: NextTriageBody): AxiosPromise {
    return axios.post('/api/v2/triage/next', nextTriageBody);
  },

  backTriage(appointmentId: String): AxiosPromise {
    return axios.post('/api/v2/triage/back', {
      appointment_id: appointmentId,
    });
  },

  getTriageJourney(appointmentId: String): AxiosPromise {
    return axios.get(`/api/v2/triage/appointment/${appointmentId}/journey`);
  },

  updateFolksamTriageJourney(folksamTriageJourneyId: String, folksamTriageJourneyBody: FolksamTriageJourneyBody): AxiosPromise {
    return axios.put(`/api/v2/triage/folksam/journey/${folksamTriageJourneyId}`, folksamTriageJourneyBody);
  },

  sendRecommendationMessage(folksamTriageJourneyId: String, mobilePhone: string): AxiosPromise {
    return axios.put(`/api/v2/triage/folksam/journey/${folksamTriageJourneyId}/send-recommendations`, { mobilePhone });
  }
};
