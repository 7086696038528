import { EcomApi, JournalApi } from '@/api';
import { SV_COUNTRY_ID, US_COUNTRY_ID } from '@/config/countries';
import { errorHandler } from '@/utils/error-handler';

const journal = {
  anamnesis: '',
  currentStatus: '',
  diagnosis: '',
  treatment: '',
  content: '',

  diagnosisCode: null,
  diagnosis_codes: [],

  appointmentType: null,
  referral_id: null,
  id: null,

  comments: [],
  appointment_data: {},
  follow_up: null,
  would_visit_vet: null,
  third_party: null,
  no_show_up: null,
  vet_approved: null,
};

export default {
  state: {
    journal,

    productRecommendations: [],
    productRecommendationsLoading: false,
    prescriptionLoading: false,
    prescription: null,

    referralTypes: [],

    loading: false,

    editComment: false,

    followUp: {
      shouldFollowUp: false,
      followUpWith: null,
      followUpAt: null,
      comment: null,
    },
    checkInAfter: 2,
  },

  getters: {
    getJournal: state => state.journal,
    getJournalComments: state => state.journal.comments,
    getFollowUp: state => state.followUp,
    getJournalId: state => state.journal.id,
    getLoading: state => state.loading,
    getIsFinished: state => state.journal?.status,
    getEditComment: state => state.editComment,
    showProductRecommendations: (_, __, rootState) =>
      rootState.user?.user?.country_id === SV_COUNTRY_ID,
    getCountryId: (_, __, rootState) => rootState.user?.user?.country_id,
    getPrescription: state => state.prescription,
    getCheckInAfter: state => state.checkInAfter,
    getReferralTypes: (state, _, rootState) => {
      const isUserFromUSA = rootState.user?.user?.country_id === US_COUNTRY_ID;

      function byCountryId(referralType) {
        const isReferToVideo = referralType.key === 'referred_to_video';
        return !isReferToVideo || isUserFromUSA;
      }

      function byReferralOrder(A, B) {
        return A.order - B.order;
      }

      return state.referralTypes.sort(byReferralOrder).filter(byCountryId);
    },
  },

  mutations: {
    setJournal: (state, payload) => {
      state.journal = payload;
    },
    setFollowUp: (state, payload) => {
      state.followUp = payload;
    },
    setJournalIfCurrent: (state, payload) => {
      if (!state.journal || state.journal.id === payload.id) {
        state.journal = payload;
      }
    },
    setJournalComments: (state, payload) => {
      state.journal.comments = payload;
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    setReferralTypes: (state, payload) => {
      state.referralTypes = payload;
    },
    setReferralType: (state, payload) => {
      state.journal.referral_id = payload;
    },
    setEditComment: (state, payload) => {
      state.editComment = payload;
    },
    addComment: (state, payload) => {
      state.journal.comments.push(payload);
    },
    editComment: (state, payload) => {
      const commentIndex = state.journal.comments.findIndex(
        comment => comment.id === payload.id
      );
      state.journal.comments[commentIndex].comment = payload.comment;
      state.journal.comments[commentIndex].status = payload.status;
      state.journal.comments[commentIndex].updated_at = payload.updated_at;
    },
    setProductRecommendations: (state, payload) => {
      state.productRecommendations = payload;
    },
    setProductRecommendationsLoading: (state, payload) => {
      state.productRecommendationsLoading = payload;
    },
    setPrescriptionLoading: (state, payload) => {
      state.prescriptionLoading = payload;
    },
    setPrescription: (state, payload) => {
      state.prescription = payload;
    },
    addProductRecommendation: (state, payload) => {
      if (
        state.productRecommendations.find(product => product.id === payload.id)
      ) {
        return;
      }

      state.productRecommendations = [
        ...(state.productRecommendations || []),
        payload,
      ];
    },
    removeProductRecommendation: (state, payload) => {
      state.productRecommendations = state.productRecommendations.filter(
        p => p.id !== payload.id
      );
    },
    setCheckInAfter: (state, payload) => {
      state.checkInAfter = payload
    },
  },

  actions: {
    async postJournalData({ commit }, payload) {
      commit('setLoading', true);
      try {
        const res = await JournalApi.update(payload);
        commit('setLoading', false);
        commit('setJournalIfCurrent', res.data.journal);
        return res;
      } catch (e) {
        commit('setLoading', false);
        return Promise.reject(e);
      }
    },
    async postFinishedJournal({ commit }, payload) {
      const { data } = await JournalApi.finish(payload);
      commit('setJournalIfCurrent', data.journal);
      return data.journal;
    },
    async fetchReferralTypes({ commit, rootState }) {
      const { locale } = rootState;
      try {
        const res = await JournalApi.fetchReferralTypes(locale);
        commit('setReferralTypes', res.data.types);
      } catch (e) {
        console.error(e);
      }
    },
    async sendJournalComment({ commit }, payload) {
      try {
        const res = await JournalApi.sendJournalComment(payload);
        commit('addComment', res.data.journal_comment);
        return res;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async editJournalComment({ commit }, payload) {
      try {
        const res = await JournalApi.editJournalComment(payload);
        commit('editComment', res.data.journal_comment);
        return res;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getProductRecommendations({ rootState, commit, state }) {
      if (rootState.platform !== 'sv') return Promise.resolve();

      try {
        commit('setProductRecommendationsLoading', true);
        const res = await EcomApi.getProductRecommendations({
          journalId: state.journal.id,
          countryId: rootState.admin.countryId,
        });
        commit('setProductRecommendations', res.data);
        commit('setProductRecommendationsLoading', false);
        return res;
      } catch (e) {
        commit('setProductRecommendationsLoading', false);
        return Promise.reject(e);
      }
    },
    async addProductRecommendation({ rootState, commit, state }, product) {
      try {
        commit('addProductRecommendation', product);
        return await EcomApi.addProductRecommendation({
          productId: product.id,
          journalId: state.journal.id,
          countryId: rootState.admin.countryId,
        });
      } catch (e) {
        commit('removeProductRecommendation', product);
        return Promise.reject(e);
      }
    },
    async removeProductRecommendation({ rootState, commit, state }, product) {
      try {
        commit('removeProductRecommendation', product);
        return await EcomApi.removeProductRecommendation({
          productId: product.id,
          journalId: state.journal.id,
          countryId: rootState.admin.countryId,
        });
      } catch (e) {
        commit('addProductRecommendation', product);
        return Promise.reject(e);
      }
    },
    async getJournalPrescriptions({ state, commit }) {
      const journalId = state.journal.id;
      try {
        commit('setPrescriptionLoading', true);
        const res = await JournalApi.fetchPrescription(journalId);
        commit('setPrescription', res.data.data);
      } catch (e) {
        errorHandler(e);
        return Promise.reject(e);
      } finally {
        commit('setPrescriptionLoading', false);
      }
    },
    async addJournalPrescription({ rootState, commit, state }, payload) {
      const journalId = state.journal.id;
      const userId = rootState.user.user.id;
      const params = {
        userId,
        prescription: payload,
      };
      try {
        const res = await JournalApi.updateOrCreatePrescription(
          journalId,
          params
        );
        commit('setPrescription', res.data.data);
      } catch (e) {
        errorHandler(e);
        return Promise.reject(e);
      }
    },
    async signJournalPrescription({ rootState, commit, state }, payload) {
      const journalId = state.journal.id;
      const prescription = payload || state.prescription.prescription;
      const userId = rootState.user.user.id;
      const params = {
        userId,
        prescription,
        signed: true,
      };
      try {
        const res = await JournalApi.updateOrCreatePrescription(
          journalId,
          params
        );
        commit('setPrescription', res.data.data);
      } catch (e) {
        errorHandler(e);
        return Promise.reject(e);
      }
    },
    clearJournal({ commit }) {
      commit('setJournal', { ...journal });
    },
  },
};
