<template>
  <div>
    <base-modal size="sm" :show="showVerificationModal">
      <h2 class="mb-2 text-lg">
        {{ $i18n.tc('call_triage.auth.revalidate_email') }}
      </h2>

      <p class="py-8">
        {{ $i18n.tc('call_triage.auth.revalidate_email_text') }}
      </p>
      <base-button
        type="button"
        color="primary"
        @click="startEmailVerification"
      >
        {{ $i18n.tc('call_triage.auth.validate') }}
      </base-button>
    </base-modal>
  </div>
</template>

<script>
import { AuthApi } from '@/api';
import { getCookie } from '@/utils/cookie-utils';

const tokenName = 'firstvetToken';

export default {
  data() {
    return {
      showVerificationModal: false,
    };
  },
  beforeMount() {
    const token = this.getTokenFromCookieOrQuery();
    this.runValidation(token);
  },
  methods: {
    async runValidation(token) {
      axios.defaults.headers.common.Authorization = token
        ? `Bearer ${token}`
        : '';
      localStorage.setItem('tempToken', token);
      try {
        await AuthApi.validateTemporaryNurse();
      } catch (error) {
        if (error.status === 423) {
          this.showVerificationModal = true;
        }
      }
    },
    getTokenFromCookieOrQuery() {
      const token = getCookie(tokenName);
      if (token) {
        return token;
      }
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(tokenName);
    },
    async startEmailVerification() {
      try {
        const { data } = await AuthApi.startEmailVerification();

        this.$router.push({
          name: 'sos-email-validation',
          query: {
            verificationRef: data.verificationRef,
          },
        });
      } catch (error) {
        console.error('error', error);
      }
    },
  },
};
</script>
