// @ts-nocheck
import Vue from 'vue';
import { AxiosError } from 'axios';
import { Sentry } from '@/plugins/sentry';
import router from '@/router/index';
import store from '@/store/index';

class ExtendedAxiosError extends Error {
  constructor({ axiosError, message, status }) {
    // Call the parent constructor with the message
    super(message);
    // Assign additional properties
    this.name = 'LockedRequestError';
    this.axiosError = axiosError;
    this.status = status;
  }
}

const showErrorNotification = e => {
  Vue.notify({
    group: 'error',
    title: `${e.status} Error` || `${e.data.status_code} Error`,
    text: e.data.message || e.data.error,
  });
};

const httpErrorHandler = (error: AxiosError, hasToken: boolean) => {
  const e = error.response;
  if (e) {
    const statusCode = e.request.status;
    const requestUrl = e.config.url;

    const isClientError = +String(statusCode).charAt(0) === 4;
    const isUnauthorizedRequest = e.request.status === 401;
    const isForbiddenRequest = e.request.status === 403;
    const isValidationError = e.request.status === 422;
    const isLockedRequest = e.request.status === 423;

    if (isUnauthorizedRequest) {
      if (hasToken) {
        store.dispatch('auth/logout');
        router.push('/');
        throw Error('Unauthorized request.');
      }
    }

    if (isForbiddenRequest) {
      showErrorNotification(e);
      throw Error('Forbidden request.');
    }

    if (isValidationError) {
      // Show validation errors as readable message
      if (e.data.errors) {
        const validationErrors = Object.values(e.data.errors);
        if (validationErrors.length) {
          const message = validationErrors.join(' ');
          throw Error(message);
        }
      }

      if (typeof e.data.message === 'string') {
        throw Error(e.data.message);
      }
    }

    if (isValidationError) {
      // Show validation errors as readable message
      const keys = Object.keys(e.data);
      const errorMessages = keys.map(key => {
        return e.data[key].join(' ');
      });
      const message = errorMessages.join(' ');
      e.data.message = message;
      showErrorNotification(e);
      return;
    }

    if (isLockedRequest) {
      throw new ExtendedAxiosError({
        axiosError: e,
        message: e.data.message,
        status: e.status,
      });
    }

    if (isClientError) {
      throw Error(e.data.message);
    }

    if (
      isUnauthorizedRequest &&
      requestUrl &&
      requestUrl.includes('vet-login')
    ) {
      // More user friendly error message when trying to login with incorrect user/pass.
      e.data.message = 'The email or password you entered is incorrect';
    }

    throw Error(e.data.message);
  }
  throw error;
};

const errorHandler = (error: Error) => {
  // const scope = new Sentry.Scope();
  // scope.setTag('type', 'Javascript Exception');
  Sentry.captureException(error);
  Vue.notify({
    group: 'error',
    title: `An error occured`,
    text: error.message,
    //   ignoreDuplicates: true,
  });
  throw error;
};

export { httpErrorHandler, errorHandler };
