import { JournalTemplatesApi } from '@/api';

const journalTemplate = {
  appointmentTypeId: null,
  animalTypeId: null,
  languageId: null,
  title: '',
  body: '',
};

const filters = {
  appointmentTypeId: null,
  animalTypeId: null,
  languageId: null,
  searchText: null,
};

const hydrateTemplate = (template, templateList) => {
  const position = templateList.findIndex(item => item.id === template.id);
  const hydrated = [...templateList];
  hydrated[position] = template;
  return hydrated;
};

export default {
  state: {
    journalTemplate,
    templatesList: {
      data: [],
    },
    filters,
    showTemplateModal: false,
    showConfirmationModal: false,
    selectedTemplate: {},
    loading: false,
  },

  getters: {
    getJournalTemplatesList: state => state.templatesList,
    getLoading: state => state.loading,
    getJournalTemplate: state => state.journalTemplate,
    getSelectedTemplate: state => state.selectedTemplate,
    getShowTemplateModal: state => state.showTemplateModal,
    getShowConfirmationModal: state => state.showConfirmationModal,
    getLanguageId: state => state.filters.languageId,
  },

  mutations: {
    getJournalTemplatesList: (state, payload) => {
      state.templatesList = payload;
    },
    setJournalTemplatesList: (state, payload) => {
      state.templatesList = payload;
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    setShowTemplateModal: (state, payload) => {
      state.showTemplateModal = payload;
    },
    setShowConfirmationModal: (state, payload) => {
      state.showConfirmationModal = payload;
    },
    setSelectedTemplate: (state, payload) => {
      state.selectedTemplate = payload;
    },
    setAppointmentTypeId: (state, appointmentTypeId) => {
      state.filters = {
        ...state.filters,
        appointmentTypeId,
      };
    },
    setAnimalTypeId: (state, animalTypeId) => {
      state.filters = {
        ...state.filters,
        animalTypeId,
      };
    },
    setLanguageId: (state, languageId) => {
      state.filters = {
        ...state.filters,
        languageId,
      };
    },
    setSearchText: (state, searchText) => {
      state.filters = {
        ...state.filters,
        searchText,
      };
    },
    resetFilters: state => {
      // Don't reset animal type id, because we always need to have one to show correct appointment types
      const { animalTypeId } = state.filters;
      state.filters = {
        ...filters,
        animalTypeId,
      };
    },
    hydrateTemplatesList: (state, template) => {
      if (Array.isArray(state.templatesList.data)) {
        state.templatesList.data = hydrateTemplate(
          template,
          state.templatesList.data
        );
      }
    },
    addTemplate: (state, payload) => {
      state.templatesList.data.unshift(payload);
    },
    deleteTemplate: (state, id) => {
      const deleted = state.templatesList.data.findIndex(
        template => template.id === id
      );
      state.templatesList.data.splice(deleted, 1);
    },
  },

  actions: {
    async fetchJournalTemplates({ commit, state }, pageUrl) {
      const payload = {
        ...state.filters,
        paginate: 10,
      };
      try {
        const res = await JournalTemplatesApi.getJournalTemplates(
          pageUrl,
          payload
        );
        commit('setJournalTemplatesList', res.data);
        return res;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    updateJournalTemplate({ commit }, { id, data }) {
      return JournalTemplatesApi.updateJournalTemplate(id, data)
        .then(res => {
          const { journal_template } = res.data; // eslint-disable-line
          commit('hydrateTemplatesList', journal_template);
          return res;
        })
        .catch(e => {
          Promise.reject(e);
        });
    },
    createJournalTemplate({ commit }, data) {
      return JournalTemplatesApi.createJournalTemplate(data)
        .then(res => {
          const { journal_template } = res.data; // eslint-disable-line
          commit('addTemplate', journal_template);
          return res;
        })
        .catch(e => {
          Promise.reject(e);
        });
    },
    deleteJournalTemplate({ commit }, id) {
      console.log(id);
      return JournalTemplatesApi.deleteJournalTemplate(id)
        .then(res => {
          commit('deleteTemplate', id);
          return res;
        })
        .catch(e => {
          Promise.reject(e);
        });
    },
  },
};
