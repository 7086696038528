<template>
  <div>
    <p v-if="errorMsg" class="text-center text-red-800">
      {{ errorMsg }}
    </p>
    <email-password-login v-if="legacyLogin" />

    <base-button
      v-else
      color="primary"
      class="my-4 w-full"
      :to="firstvetAuthUrl"
      :href="firstvetAuthUrl"
      data-testid="login-button"
    >
      Sign in
    </base-button>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import Echo from '@/plugins/echo';
import { UK_COUNTRY_ID } from '@/config/countries';
import { authMethods } from '@/store/auth-helpers';
import { FIRSTVET_AUTH_URL, BASE_URL } from '@/constants';
import EmailPasswordLogin from './EmailPasswordLogin.vue';

export default {
  components: { EmailPasswordLogin },
  props: {
    legacyLogin: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      FIRSTVET_AUTH_URL,
    };
  },
  computed: {
    firstvetAuthUrl() {
      return `${FIRSTVET_AUTH_URL}/uk/country-select/?hide_countries=us&platform=vetpanel&source=web.vetpanel&redirect_url=${BASE_URL}/uk`;
    },
  },
  mounted() {
    this.setPlatform('intl');
    Echo.connector.pusher.config.authEndpoint = `${process.env.VUE_APP_API_URL_INTL}/broadcasting/auth`;

    localStorage.setItem('selectedCountry', UK_COUNTRY_ID);
    localStorage.setItem('locale', 'uk');
    this.setCountry(UK_COUNTRY_ID);
  },

  methods: {
    ...authMethods,
    ...mapMutations('admin', ['setCountry']),
    ...mapActions(['setPlatform']),
  },
};
</script>
