// eslint-disable-next-line import/no-cycle
import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  authenticateAdmin(payload: any): AxiosPromise {
    return axios.post('/api/auth/login/panel', payload);
  },

  login(payload: any): AxiosPromise {
    return axios.post('/api/auth/vet-login', payload);
  },

  loginTwoFactor(payload: any): AxiosPromise {
    return axios.post('/api/auth/user/sms/code/start', payload, {
      headers: {
        Authorization: `Bearer ${payload.token}`,
        'Access-Control-Allow-Origin': '*',
      },
    });
  },
  submitCodeTwoFactor(code: string, token: string): AxiosPromise {
    return axios.post(
      '/api/auth/user/sms/code/check',
      { code },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
  },

  requestNewPassword(payload: any): AxiosPromise {
    return axios.post('/api/auth/request-new-password', payload);
  },

  resetPassword(payload: any): AxiosPromise {
    return axios.post('/api/auth/reset-password', payload);
  },

  validateTemporaryNurse(): AxiosPromise {
    return axios.post('/api/v2/remote-booking/validate-account');
  },
  startEmailVerification(): AxiosPromise {
    return axios.post('/api/v2/remote-booking/verify-email/start');
  },
  confirmEmailVerification(payload: any): AxiosPromise {
    return axios.post('/api/v2/remote-booking/verify-email/confirm', payload);
  }
};
