<template>
  <div class="flex h-full items-center justify-center">
    <div class="w-full lg:w-2/5">
      <card class="mx-2 mb-0 justify-center">
        <div class="flex flex-col space-x-6 py-4">
          <div class="w-full pb-2 text-center">
            <img
              :src="require('@/assets/svg/firstvet_logo.svg')"
              class="mx-auto h-12 w-auto"
            />
          </div>
        </div>

        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form
            v-if="!passwordIsSet"
            class="mx-8 flex flex-wrap justify-center"
            @submit.prevent="handleSubmit(submit)"
          >
            <p
              v-if="!passwordIsSet"
              class="mt-4 mb-6 w-full border-b pb-2 text-left text-lg font-semibold"
            >
              Set your new password
            </p>

            <ValidationProvider
              v-slot="{ errors }"
              rules="required|min:8"
              name="password"
              vid="password"
              tag="div"
              class="mb-4 w-full"
            >
              <base-label for="password"> Password </base-label>
              <base-input
                id="password"
                v-model="password"
                type="password"
                placeholder="***********"
                name="password"
              />
              <p v-if="errors.length" class="mt-2 text-red-800">
                {{ errors[0] }}
              </p>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ required: true, is: password }"
              name="passwordConfirmation"
              vid="passwordConfirmation"
              class="mb-4 w-full"
            >
              <div v-if="password.length > 7">
                <base-label for="passwordConfirmation">
                  Confirm password
                </base-label>
                <base-input
                  id="passwordConfirmation"
                  v-model="passwordConfirmation"
                  type="password"
                  placeholder="***********"
                  name="passwordConfirmation"
                />
                <p v-if="errors.length" class="mt-2 text-red-800">
                  {{ errors[0] }}
                </p>
              </div>
            </ValidationProvider>
            <p v-if="error" class="text-red-700">
              {{ error }}
            </p>
            <base-button
              :loading="loading"
              color="primary"
              class="mb-6 w-full text-center"
              :disabled="loading"
            >
              Submit
            </base-button>
          </form>
        </ValidationObserver>

        <div
          v-if="passwordIsSet"
          class="flex flex-col items-center justify-center"
        >
          <fv-icon
            icon="check-mark"
            class="mr-3 mb-2 text-fv-green"
            size="3x"
          />
          <p class="mt-2 mb-6 text-center text-xl font-semibold">
            Your new password has been set!
          </p>
          <base-button
            :loading="loading"
            color="primary"
            class="mb-6 mt-6 w-full text-center"
            @click="showTwoFactorModal = true"
          >
            Sign in
          </base-button>
          <div />
        </div>
      </card>
    </div>
    <two-factor-modal
      :show="showTwoFactorModal"
      :phone-number="phoneNumber"
      :country-code="countryCode"
      :temp-token="tempToken"
      @close="showTwoFactorModal = false"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TwoFactorModal from '@/components/auth/TwoFactorModal.vue';
import { AuthApi } from '@/api';
import { authMethods } from '@/store/auth-helpers';
export default {
  components: {
    TwoFactorModal,
  },
  data() {
    return {
      loading: false,
      password: '',
      passwordConfirmation: '',
      email: '',
      error: '',
      logo: '',
      logoError: '',
      name: '',
      showTwoFactorModal: false,
      phoneNumber: '',
      tempToken: '',
      countryCode: 3,
      passwordIsSet: false,
    };
  },
  mounted() {
    this.getValuesfromQuery();
    this.fetchCountryCodes();
  },
  methods: {
    ...authMethods,
    ...mapActions('country', ['fetchCountryCodes']),
    getValuesfromQuery() {
      this.email = this.$router.currentRoute.query.email;
      this.token = this.$router.currentRoute.query.token;
    },
    async submit() {
      this.loading = true;
      this.error = '';
      try {
        const res = await AuthApi.resetPassword({
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          token: this.token,
        });
        this.$notify({
          group: 'auth',
          title: 'Success!',
          text: `Password was successfully set`,
        });
        this.passwordIsSet = true;
        this.phoneNumber = res.data.user.mobile_phone
          ? res.data.user.mobile_phone
          : '';
        this.countryCode = res.data.user.country_code.id;
        this.loading = false;
        this.tempToken = res.data.token;
      } catch (e) {
        this.loading = false;
        this.error = e.message;
      }
    },
  },
};
</script>
