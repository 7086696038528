<template>
  <div class="relative h-full">
    <div class="flex h-full flex-col items-center justify-center">
      <div class="w-full max-w-4xl px-4">
        <card class="mx-auto py-12">
          <div class="mx-auto flex h-full max-w-2xl flex-col justify-center">
            <div class="mb-4 flex flex-wrap justify-start px-8">
              <div class="w-full pb-12">
                <div class="flex justify-between -space-x-1">
                  <div class="flex items-center">
                    <img
                      v-for="imageName in imageNames"
                      :key="imageName"
                      class="h-6 w-auto"
                      alt="firstvet logo"
                      :src="require(`@/assets/svg/flags/${imageName}.svg`)"
                    />
                  </div>
                  <div class="justify-self-end">
                    <v-select
                      v-model="currentPlatform"
                      placeholder="Select region"
                      class="w-full min-w-40"
                      :options="regions"
                      label="name"
                      value-attr="id"
                      :reduce="at => at.id"
                      :clearable="false"
                      @input="goToRegionLogin"
                    />
                  </div>
                </div>
              </div>
              <img
                alt="FirstVet logo"
                :src="require('@/assets/svg/firstvet_logo.svg')"
                class="mx-auto h-12 w-auto"
              />
            </div>

            <component
              :is="loginComponent"
              :legacy-login="legacyLogin"
              :clinic-slug="clinicSlug"
              :error-msg="errorMsg"
            />
          </div>
          <div class="flex w-full flex-col items-center justify-center">
            <button
              class="flex items-center text-sm text-gray-700 hover:text-gray-900"
              data-testid="change-login"
              @click="legacyLogin = !legacyLogin"
            >
              {{ legacyLogin ? 'Use new login' : 'Use old login' }}
              <fv-icon icon="chevron-right" size="xs" />
            </button>

            <button
              class="flex items-center text-sm text-gray-700 hover:text-gray-900"
              data-testid="sos-login"
              @click="
                () => {
                  $router.push({
                    name: 'sos',
                    params: {
                      platform: 'sv',
                    },
                  });
                }
              "
            >
              sos.eu login
              <fv-icon icon="chevron-right" size="xs" />
            </button>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import LoginIntl from '@/components/auth/LoginIntl';
import LoginSv from '@/components/auth/LoginSv';
import LoginUs from '@/components/auth/LoginUs';
import DigitalClinicApi from '@/api/modules/admin/digital-clinic';
import { BASE_URL, FIRSTVET_TOKEN_COOKIE_DOMAIN } from '@/constants';
import { getCookie, setCookie } from '@/utils/cookie-utils';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    LoginIntl,
    LoginSv,
    LoginUs,
  },
  data() {
    return {
      name: '',
      BASE_URL,
      legacyLogin: false,
      errorMsg: '',
      currentPlatform: 'intl',
    };
  },
  computed: {
    regions() {
      return [
        {
          id: 'intl',
          path: '',
          name: 'EU',
        },
        {
          id: 'sv',
          path: 'sv',
          name: 'Sweden',
        },
        {
          id: 'us',
          path: 'us',
          name: 'United States',
        },
      ];
    },
    imageNames() {
      switch (this.currentPlatform) {
        case 'sv':
          return ['sv'];
        case 'us':
          return ['en'];
        default:
          return ['uk', 'no', 'fi', 'dk', 'de'];
      }
    },

    loginComponent() {
      switch (this.currentPlatform) {
        case 'sv':
          return 'LoginSv';
        case 'intl':
          return 'LoginIntl';
        case 'us':
          return 'LoginUs';
        default:
          return 'LoginIntl';
      }
    },
    clinicSlug() {
      return this.$router.currentRoute.query.clinic || false;
    },
    ...mapGetters({
      platform: 'getPlatform',
    }),
  },
  watch: {
    $route(to) {
      this.currentPlatform = to.params.platform;
    },
  },
  beforeMount() {
    this.currentPlatform = this.$router.currentRoute.params.platform;
  },

  async mounted() {
    if (this.clinicSlug) {
      this.getClinic(this.clinicSlug);
    }

    if (getCookie('firstvetToken')) {
      localStorage.token = getCookie('firstvetToken');

      setCookie({
        name: 'firstvetToken',
        value: '',
        domain: FIRSTVET_TOKEN_COOKIE_DOMAIN,
        maxAge: 0,
      });
      try {
        await this.setAuthData(localStorage.token);

        this.handleLoginRoutePush();
      } catch (error) {
        this.errorMsg = error.message;
        setCookie({
          name: 'firstvetToken',
          value: '',
          domain: FIRSTVET_TOKEN_COOKIE_DOMAIN,
          maxAge: 0,
        });
      }
    }
  },
  methods: {
    ...mapActions('auth', ['setAuthData', 'handleLoginRoutePush']),
    ...mapActions('user', ['fetchUser']),
    ...mapActions(['setPlatform']),
    goToRegionLogin(event) {
      const regionPath = this.regions.find(region => region.id === event).path;

      this.$router.push({
        name: 'login',
        params: {
          platform: regionPath,
        },
      });
    },
    getClinic(slug) {
      return DigitalClinicApi.fetchClinic(slug)
        .then(res => {
          if (res.data.logo) {
            this.logo = res.data.logo;
          } else {
            this.logoError = true;
            this.name = res.data.name;
          }
        })
        .catch(() => {
          this.logoError = true;
        });
    },
  },
};
</script>
