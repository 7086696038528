<template>
  <div class="relative h-full">
    <div class="flex h-full flex-col items-center justify-center">
      <div class="w-full max-w-4xl px-4">
        <card class="mx-auto py-12">
          <div class="mx-auto flex h-full max-w-2xl flex-col justify-center">
            <div class="mb-4 flex flex-wrap justify-start px-8">
              <div class="w-full pb-12"></div>
              <!--TODO: vue3 replace require with static import-->
              <img
                alt="FirstVet logo"
                :src="require('@/assets/svg/firstvet_logo.svg')"
                class="mx-auto h-12 w-auto"
              />
            </div>
            <div v-if="currentUiState === UI_STATES.error">
              <p>{{ $i18n.tc('call_triage.auth.no_token') }}</p>
            </div>
            <div v-else-if="currentUiState === UI_STATES.loading">
              <div class="flex justify-center">
                <base-spinner size="xxl" :loading="true" />
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <RevalidateEmailModal v-if="showRevalidateEmailModal" />
  </div>
</template>
<script>
import { FIRSTVET_TOKEN_COOKIE_DOMAIN } from '@/constants';
import { getCookie, setCookie } from '@/utils/cookie-utils';
import RevalidateEmailModal from '@/components/call-triage/authentication/RevalidateEmailModal.vue';
import { mapActions, mapGetters } from 'vuex';
import { AuthApi } from '@/api';

const tokenName = 'firstvetToken';
const UI_STATES = {
  idle: 'idle',
  loading: 'loading',
  error: 'error',
};

export default {
  components: {
    RevalidateEmailModal,
  },
  data() {
    return {
      UI_STATES,
      currentUiState: UI_STATES.loading,
      showRevalidateEmailModal: false,
    };
  },
  computed: {
    ...mapGetters({
      platform: 'getPlatform',
    }),
  },
  beforeMount() {
    this.setPlatform('sv');
  },

  async mounted() {
    if (this.getTokenFromCookieOrQuery()) {
      const token = this.getTokenFromCookieOrQuery();
      this.removeCookie();
      if (await this.runValidation(token)) {
        this.pushCallTriageRoute(token);
      }
    } else {
      this.currentUiState = UI_STATES.error;
    }
  },
  methods: {
    ...mapActions('auth', [
      'handleDefaultHeaders',
      'handleLoginRoutePush',
      'setAuthData',
    ]),
    ...mapActions(['setPlatform']),
    async pushCallTriageRoute(token) {
      await this.setAuthData(token);
      this.$router.push({
        name: 'call-triage-dashboard',
      });
    },
    async runValidation(token) {
      // TODO: vue3 add axios import
      axios.defaults.headers.common.Authorization = token
        ? `Bearer ${token}`
        : '';
      AuthApi.validateTemporaryNurse().catch(() => {
        this.showRevalidateEmailModal = true;
        return false;
      });
      return true;
    },
    getTokenFromCookieOrQuery() {
      const token = getCookie(tokenName);
      if (token) {
        return token;
      }
      return this.$route.query?.firstvetToken;
    },
    removeCookie() {
      setCookie({
        name: tokenName,
        value: '',
        domain: FIRSTVET_TOKEN_COOKIE_DOMAIN,
        maxAge: 0,
      });
    },
  },
};
</script>
