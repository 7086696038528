const ukTranslations = {
  select_for_referral: 'Select clinic',
  filter: 'Filter',
  active: 'Active',
  inactive: 'Inactive',
  value_missing: 'Value missing',
  field_cannot_be_empty: 'Field cannot be empty',
  fetch_address_info: 'Fetch location info',
  information_found: 'Information found',
  address: 'Address',
  latitude: 'Latitude',
  longitude: 'Longitude',
  is_information_correct: 'Does this look accurate?',
  save_coordinates: 'Save coordinates',
  save_and_move_cursor_sideways: 'Save and move cursor sideways',
  could_not_find_any_address_info:
    'Could not find any results. Please make sure the address is correct.',
  something_went_wrong: 'Something went wrong. Please try again later.',
  address_missing: 'Address is missing.',
  cancel: 'Cancel',
  type_of_animal: 'Type of animals',
  choose_type_of_animal: 'Choose type',
  clinic_accepts: 'The clinic accepts',
  add_animal_type: 'Add animal type',
  add: 'Add',
  contact_information: 'Contact details',
  name: 'Name',
  city: 'City',
  municipality: 'County',
  country: 'Country',
  email: 'Email',
  phone: 'Phone',
  website: 'Website',
  status: 'Status',
  '2288-pilot': '2228-pilot',
  size_general_info: 'Size and general information',
  clinic_type: 'Clinic type',
  number_of_vets: 'Number of vets',
  small_animals: 'Small animals',
  horse: 'Horse',
  general_info: 'General information',
  information_horse_service: 'Information horse service',
  referral: 'Referral',
  email_referral: 'Email for referral',
  phone_referral: 'Phone number for referral',
  referral_information: 'Referral information',
  location_info: 'Information about location',
  coordinates: 'Coordinates',
  services: 'Services',
  add_specific_service: 'Add/remove services',
  animal_group: 'Animal category',
  service_category: 'Service category',
  save_services: 'Save services',
  availability: 'Availability',
  emergency_care_options: 'Emergency care (treatment options)',
  emergency_care: 'Emergency care',
  on_call_options: 'On call service',
  inpatient_care_small_animals: 'Inpatient care (small animals)',
  inpatient_care_horses: 'Inpatient care (horses)',
  yes: 'Yes',
  no: 'No',
  opening_hours: 'Opening hours',
  opens: 'Opens',
  closes: 'Closes',
  closed: 'Closed',
  save_opening_hours: 'Save opening hours',
  opening_hours_saved: 'Opening hours saved',
  other_opening_hours: 'Other opening hours',
  other_opening_hours_placeholder: 'Ex. closed for lunch',
  opening_hours_error:
    'Something went wrong. Please make sure the closing hour is after the opening hour.',
  opening_hours_missing_error:
    'Both opening and closing hour must be filled (unless closed).',
  selected_time_is_in_wrong_format: 'Selected time is in wrong format',
  copy_contact_info: 'Copy contact info',
  copy_clinic_name: 'Copy clinic name',
  copied_to_clipboard: 'Copied to clipboard!',
  phone_number: 'Phone number',
  public: 'Public',
  referral_hidden: 'Referral (hidden)',
  inpatient_care: 'Inpatient care',
  general_information: 'General information',
  horses: 'Horses',
  horses_field: 'Horses (field)',
  animal_species: 'Animal species',
  agricultural_animals: 'Agricultural animals',
  referral_info: 'Referral info',
  search: 'Search',
  no_search_results: 'No search results',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  sat: 'Sat',
  sun: 'Sun',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  currently_open: 'Currently open',
  clinics: 'Clinics',
  active_areas: 'Active areas',
  distances: 'Distances',
  save: 'Save',
  no_answer: 'No answer given',
  generic_error: 'Something went wrong, refresh the page and try again',
  clinic_map: {
    clinic_search_placeholder: 'Search for a clinic or place',
    clinic_search_prompt: 'Start typing to search',
    clinics: 'Clinics',
    move_around_prompt: 'Move around or pan out to find more clinics.',
    view_full_list: 'View full list',
    recommendations: 'Recommendations',
    no_results_for: 'No results found for',
  },
  clinic_map_send_form: {
    clinic: 'Clinic',
    mobile_phone: 'Mobile phone number',
    country_code: 'Country code',
    price: 'Price',
    quality: 'Quality',
    insurance: 'Insurance',
    rating: 'Rating',
    cta: 'Send',
    success: 'Recommendations were successfully sent!',
    free: 'Free',
  },
  remote_booking: {
    select_timeslot: {
      dialog_header: 'Select a time',
      dialog_description:
        'Select a time for the appointment.\nThe meeting will take place in our app.',
      no_slots: 'No available slots',
    },
    case_description: {
      title: 'Describe the case',
      description:
        'Please describe the case in as much detail as possible, so our vet can help you in the best way.',
      cta: 'Continue',
    },
    confirm_booking: {
      header_title: 'Confirm booking',
      header_description:
        'Make sure the information is correct and confirm the booking to send booking information to the pet parent.',
      appointment_time: 'Time of appointment',
      phone_number: 'Phone number',
      pet: 'Pet',
      vet: 'Vet',
    },
    sms_confirm: {
      header_title: 'Success! Message sent',
      header_description:
        'We have sent an SMS with a link to a booking confirmation page. Ask if the pet parent has received a message, and let them know they need to confirm the booking for it to go through.',
      waiting_for_confirmation: 'Waiting for confirmation',
      no_sms: 'SMS not received?',
      resend: 'Send again',
      whats_next: {
        title: `What's next?`,
        paragraph_1:
          'The meeting will take place in our app and the booking information has been sent to the pet parent.',
        paragraph_2:
          'Pet parent has to download the app from app store / play store. After logging in they will be able to access their account and the appointment.',
        paragraph_3:
          'They will find the booking on the home screen of the app.',
      },
    },
  },
  call_triage: {
    auth: {
      no_token: 'No token found',
      only_for_sos_users: 'Only for SOS.eu users',
      login_signup: 'Login or sign up',
      could_not_validate_email: 'Could not validate email',
      confirm: 'Confirm',
      revalidate_email: 'Revalidate email',
      revalidate_email_text:
        'You need to revalidate your email address to continue.',
      validate: 'Validate',
    },
    remote_booking_widget: {
      confirm_booking: {
        cta: 'Send text message',
      },
    },
    lookup: {
      lookup: 'Search',
      ssn: 'SSN',
      search: 'Search',
      phone_number: 'Phone number',
      insurance_company: 'Insurance company',
      clear_form: 'Clear form',
      select_pet_to_start: 'Select an animal to start',
      lookup_successful: 'Search successful',
      cant_find_pet: 'Can’t find your pet?',
      proceed_to_clinic_map: 'Proceed to clinic map',
      no_animals: 'No animals found',
      no_animals_message: "Couldn't find any animals for this user",
    },
    recommendation: {
      recommendation: 'Recommendation',
      give_advice: 'Give advice',
      book_appointment: 'Book appointment',
      go_to_clinic: 'Go to clinic',
      additional_information: 'Additional information',
      triage_title: 'Triage',
      book_appointment_with_firstvet: 'Book appointment with FirstVet',
      book_appointment_description:
        'Advise and book a video appointment with a FirstVet veterinarian for the user. Select a suitable time in the booking process, after which the user will receive a confirmation via SMS.',
      no_insurance_company_selected: 'No insurance company selected',
      complete: 'Complete',
      send_recommendation: 'Send recommendation',
      data_tracking: {
        message:
          'Something went wrong, we were unable to track the data, please retry',
        retry: 'Retry',
      },
      pill: {
        recommended: 'Recommended',
        advice:
          'Based on the answers given, this case can most likely be resolved on the phone',
        booking:
          'Based on the answers given, this case can most likely be resolved by a vet on a video call',
        clinic:
          'Based on the answers given, this case will most likely most likely need to go to a physical clinic',
      },
      disabled_tooltip: 'No selected animal',
      send_recommendations: {
        checkbox: "Send 'Additional information' to customer via SMS",
        error: {
          title: 'Additional information is missing',
          message: 'Toggle off SMS if there is nothing to write down.',
        },
      },
      saving: 'Saving...',
    },
    triage: {
      title: 'Triage',
      start: 'Start triage',
      next: 'Next',
      previous: 'Previous',
      please_select_option: 'Please select an option',
      triage_loading_error: 'Could not find or load triage journey',
      additional_information: 'Additional information',
    },
    booking: {
      pet: 'Animal',
      service: 'Service',
      vet: 'Veterinarian',
      date_and_time: 'Date and time',
      duration: 'Duration',
      select_service: 'Select a service',
      services_available_description:
        'The following FirstVet services are available for {company}',
      free: 'Free',
    },
    summary: {
      copy: 'Copy to clipboard',
      new_customer: 'New customer',
      service: {
        clinic: 'Clinic information recommended',
        advice: 'Advice over the phone recommended',
        booking: 'FirstVet appointment recommended',
      },
      no_service: 'No recommendation found',
      result: 'Triage result',
      additional_information: 'More information',
      no_additional_information: 'No additional information',
      follow_up: 'Follow-up',
      no_follow_up_required: 'No follow-up required',
      phone_advice: 'Advice given over the phone',
      recommendation_label: 'Recommendation',
      booking_for_customer_label: 'FirstVet booking created for customer',
      referred_for_follow_up: 'Client referred to clinic for follow-up',
      clinic_name: 'Name of the clinic',
      clinic_address: 'Clinic address',
      booking: 'Booking',
      no_vet: 'No vet assigned',
      vet: 'Veterinary',
      start_meeting: 'The start of the meeting',
      not_verified: 'Not verified',
      verified: 'Verified',
      journey_finished: {
        message:
          'This journey has already ended. Please start a new triage journey',
        redirect_button: 'New customer',
      },
      started_at: 'Started at',
      completed_at: 'Completed at',
      clinic_recommendation_link: 'Clinic recommendation link',
      booking_confirm_link: 'Booking confirmation link',
      recommendations_message_send: {
        sending: 'SMS sending',
        sent: 'SMS sent',
        error: 'Could not send SMS',
        try_again: 'Try again',
      },
    },
  },
  free: 'Free',
};

export default ukTranslations;
