/* eslint-disable import/no-cycle */
import { Ability } from '@casl/ability';
import { TYPE_KEY } from '@/utils/ability-type';
import { AuthApi } from '@/api';
import updateAbility from '@/plugins/casl/ability';
import router from '@/router';
import { setCookie } from '@/utils/cookie-utils';
import { FIRSTVET_TOKEN_COOKIE_DOMAIN } from '@/constants';

const ADMIN_ROLE_ID = 1;
const VET_ROLE_ID = 3;

// ===
// Private helpers
// ===

function getSavedState(key) {
  return localStorage.getItem(key);
}

function saveState(key, state) {
  localStorage.setItem(key, state);
}

function removeState(key) {
  localStorage.removeItem(key);
}

function getPlatformToken(platform) {
  switch (platform) {
    case 'sv':
      return getSavedState('svToken');
    case 'intl':
      return getSavedState('intlToken');
    case 'us':
      return getSavedState('usToken');
  }
}

function setDefaultAuthHeaders(state, platform) {
  const token = getPlatformToken(platform);

  axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : '';
  axios.defaults.headers.common['Accept-Language'] = state.locale;
}

function savePlatformSpecificToken(platform, token, commit) {
  switch (platform) {
    case 'sv':
      commit('setSvToken', token);
      saveState('svToken', token);
      break;
    case 'intl':
      commit('setIntlToken', token);
      saveState('intlToken', token);
      break;
    case 'us':
      commit('setUsToken', token);
      saveState('usToken', token);
      break;
  }
}

export default {
  state: {
    token: getSavedState('token') || '',
    locale: getSavedState('locale') || '',
    svToken: getSavedState('svToken') || '',
    intlToken: getSavedState('intlToken') || '',
    usToken: getSavedState('usToken') || '',
    authenticated: false,
    roles: null,

    isDigitalClinicAdmin: false,
    permissions: [],
    use2fa: false,
  },

  getters: {
    getToken: state => state.token,
    isAuthenticated: state => state.authenticated,
    getRoles: state => state.roles,

    isFolksamTriageNurse: state => {
      if (state.roles) {
        return !!state.roles.find(role => role.name === 'FolksamTriageNurse');
      }
      return false;
    },

    isAdmin: state => {
      if (state.roles) {
        return !!state.roles.find(
          role => (role.id || role.role_id) === ADMIN_ROLE_ID
        );
      }
      return false;
    },
    isVet: state => {
      if (state.roles) {
        return !!state.roles.find(role => role.id === VET_ROLE_ID);
      }
      return false;
    },
    isDigitalClinicUser: state => {
      if (state.roles) {
        return !!state.roles.find(role => {
          if (role.name) {
            return role.name.includes('DigitalClinic');
          }
          return false;
        });
      }
      return false;
    },
    isReferralCoordinator: state => {
      if (state.roles) {
        return !!state.roles.find(role => {
          if (role.name) {
            return role.name.includes('ReferralAgent');
          }
          return false;
        });
      }
      return false;
    },
    isDigitalClinicAdmin: state => state.isDigitalClinicAdmin,
    canManageQuestions: state => {
      if (state.roles) {
        return !!state.roles.find(role => role.name === 'QuestionManager');
      }
      return false;
    },
    isPrescriptionsWriter: state =>
      !!state.roles?.find(role => role.name === 'PrescriptionsWriter'),

    ability() {
      return new Ability([], {
        subjectName(subject) {
          return !subject || typeof subject === 'string'
            ? subject
            : subject[TYPE_KEY];
        },
      });
    },

    getPlatformTokens: state => ({
      intl: state.intlToken,
      sv: state.svToken,
      us: state.usToken,
    }),

    getCurrentPlatformToken: (state, _getters, rootState) => {
      const { platform } = rootState;

      switch (platform) {
        case 'sv':
          return state.svToken;
        case 'intl':
          return state.intlToken;
        case 'us':
          return state.usToken;
      }
    },
  },

  mutations: {
    setToken: (state, payload) => {
      state.token = payload;
    },
    setAuth: (state, payload) => {
      state.authenticated = payload;
    },
    setRoles: (state, payload) => {
      state.roles = payload;
    },
    setLocale: (state, payload) => {
      state.locale = payload;
    },
    setIntlToken: (state, payload) => {
      state.intlToken = payload;
    },
    setSvToken: (state, payload) => {
      state.svToken = payload;
    },
    setUsToken: (state, payload) => {
      state.usToken = payload;
    },
    setIsDigitalClinicAdmin: (state, payload) => {
      state.isDigitalClinicAdmin = payload;
    },
    setPermissions: (state, claims) => {
      updateAbility(claims);
      if (claims && claims.permissions) {
        state.permissions = claims.permissions;
      }
    },
  },

  actions: {
    async loginTwoFactor(_, payload) {
      const res = await AuthApi.loginTwoFactor(payload);
      return res;
    },

    async submitCodeTwoFactor(_, { code, token }) {
      try {
        const res = await AuthApi.submitCodeTwoFactor(code, token);
        if (res.data.message === 'success') {
          return res.data;
        }
        return Promise.reject(new Error('Invalid code'));
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async login({ commit }, payload) {
      try {
        const res = await AuthApi.login(payload);
        commit('setLocale', res.data.locale);
        saveState('locale', res.data.locale);
        return res;
      } catch (e) {
        commit('setAuth', false);
        return Promise.reject(e);
      }
    },

    logout({ commit, state }) {
      return new Promise(resolve => {
        setCookie({
          name: 'firstvetToken',
          value: '',
          domain: FIRSTVET_TOKEN_COOKIE_DOMAIN,
          maxAge: 0,
        });
        commit('setAuth', false);
        commit('setToken', '');
        commit('setRoles', null);
        commit('setPermissions', null);

        commit('setIntlToken', '');
        commit('setSvToken', '');
        commit('setUsToken', '');

        commit('user/setUser', null, { root: true });

        removeState('token');
        removeState('user');
        removeState('locale');
        removeState('platform');

        removeState('svToken');
        removeState('intlToken');
        removeState('usToken');

        setDefaultAuthHeaders(state);
        resolve();
      });
    },

    resetPassword(_, payload) {
      return AuthApi.resetPassword(payload)
        .then(res => {
          console.log('pwrest', res);
        })
        .catch(e => {
          console.log(e);
        });
    },
    setPlatformSpecificToken({ commit, rootState }, payload) {
      savePlatformSpecificToken(rootState.platform, payload, commit);
    },

    setAuthData({ commit, dispatch, state, rootState }, payload) {
      return new Promise((resolve, reject) => {
        try {
          savePlatformSpecificToken(rootState.platform, payload, commit);
          setDefaultAuthHeaders(state, rootState.platform);
          commit('setAuth', true);
          dispatch('user/fetchUser', {}, { root: true })
            .then(() => {
              resolve(true);
            })
            .catch(e => {
              reject(e);
            });
        } catch (e) {
          reject(e);
        }
      });
    },

    handleDefaultHeaders({ state }, payload) {
      const platform = payload;
      setDefaultAuthHeaders(state, platform);
    },

    handleLoginRoutePush({ getters, state }) {
      if (getters.isAdmin) {
        router.push({ name: 'schedule-dashboard' });
        return;
      }
      if (getters.isReferralCoordinator) {
        router.push({ name: '2288-dashboard' });
        return;
      }

      if (getters.isFolksamTriageNurse) {
        router.push({ name: 'call-triage-dashboard' });
        return;
      }
      router.push({ name: 'dashboard' });
    },
  },
};
