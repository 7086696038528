/* eslint-disable no-underscore-dangle */

const __PROD__ = process.env.NODE_ENV === 'production';

const __STAGING__ = process.env.NODE_ENV === 'staging';

const __DEV__ = !__PROD__ && !__STAGING__;

const FEATURE_FLAG = __STAGING__ || __DEV__;

const API_URL_INTL = process.env.VUE_APP_API_URL_INTL;

const API_URL_SV = process.env.VUE_APP_API_URL_SV;

const API_URL_US = process.env.VUE_APP_API_URL_US;
const VETPANEL_SV_URL = `${API_URL_SV}/vetpanel/`;

const SENTRY_URL = process.env.VUE_APP_SENTRY_URL;

const BASE_URL = process.env.VUE_APP_BASE_URL;

const FIRSTVET_AUTH_URL = process.env.VUE_APP_FIRSTVET_AUTH_URL;

const FIRSTVET_AUTH_US_URL = process.env.VUE_APP_FIRSTVET_AUTH_US_URL;
const FIRSTVET_TOKEN_COOKIE_DOMAIN =
  process.env.VUE_APP_FIRSTVET_TOKEN_COOKIE_DOMAIN;

const IS_CALL_TRIAGE_ENABLED =
  process.env.VUE_APP_IS_CALL_TRIAGE_ENABLED === 'true';

const SERVICES = {
  VETERINARY: 1,
  BEHAVIORAL: 3,
  DENTAL: 4,
  REHAB: 5,
  PRESCRIPTIONS: 6,
  NUTRITIONAL: 9,
  HEALTH_CONTROL: 11,
  VET_TECH: 23,
};

export {
  __PROD__,
  __STAGING__,
  __DEV__,
  FEATURE_FLAG,
  API_URL_INTL,
  API_URL_SV,
  API_URL_US,
  SENTRY_URL,
  BASE_URL,
  VETPANEL_SV_URL,
  FIRSTVET_AUTH_URL,
  FIRSTVET_AUTH_US_URL,
  FIRSTVET_TOKEN_COOKIE_DOMAIN,
  SERVICES,
  IS_CALL_TRIAGE_ENABLED,
};
